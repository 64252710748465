import React, {useState} from 'react';
import {Box, Button, MenuItem, Stack, TextField, Typography} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import * as yup from 'yup';
import ToastSuccess from "../../components/modals/ToastSuccess";
import {useNavigate} from "react-router-dom";

const validationSchema = yup.object().shape({
    width: yup
        .number()
        .required('Укажите ширину оконного проема')
        .positive('Ширина должна быть положительным числом')
        .integer('Введите целое число')
        .min(300, 'Минимальная ширина — 300 мм'),
    height: yup
        .number()
        .required('Укажите высоту оконного проема')
        .positive('Высота должна быть положительным числом')
        .integer('Введите целое число')
        .min(300, 'Минимальная высота — 300 мм'),
    profileThickness: yup.string().required('Выберите толщину оконного профиля'),
    glassType: yup.string().required('Выберите тип стеклопакета'),
    numOfSections: yup
        .number()
        .required('Укажите количество створок')
        .integer('Введите целое число')
        .min(1, 'Минимум 1 створка')
        .max(3, 'Максимум 3 створки'),
    numOfOpeningSections: yup
        .number()
        .required('Укажите количество открывающихся створок')
        .integer('Введите целое число')
        .min(0, 'Не может быть меньше 0')
        .test(
            'is-less-or-equal-to-sections',
            'Количество открывающихся створок не может превышать количество створок',
            function (value) {
                return value <= this.parent.numOfSections;
            }
        ),
    name: yup.string().required('Укажите ваше имя'),
    email: yup
        .string()
        .email('Введите корректный email')
        .required('Укажите ваш email'),
    phone: yup
        .string()
        .matches(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, 'Введите корректный номер телефона в формате +7 (XXX) XXX-XX-XX')
        .required('Укажите номер телефона'),
});


const CalculatorForm = () => {
    const navigate = useNavigate();
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
    const [formData, setFormData] = useState({
        width: 1000,
        height: 1000,
        profileThickness: '',
        glassType: '',
        numOfSections: 2,
        numOfOpeningSections: 1,
        name: '',
        email: '',
    });

    const formatPhoneNumber = (value) => {
        const digits = value.replace(/\D/g, '');

        if (digits.length <= 1) return '+7';
        if (digits.length <= 4) return `+7 (${digits.slice(1)}`;
        if (digits.length <= 7) return `+7 (${digits.slice(1, 4)}) ${digits.slice(4)}`;
        if (digits.length <= 9) return `+7 (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7)}`;
        return `+7 (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7, 9)}-${digits.slice(9, 11)}`;
    };

    const handlePhoneChange = (e) => {
        const {value} = e.target;

        const formattedValue = formatPhoneNumber(value);

        setFormData({...formData, phone: formattedValue});
    };

    const handlePhoneKeyPress = (e) => {
        if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
            e.preventDefault();
        }
    };


    const [errors, setErrors] = useState({});

    const theme = createTheme({
        typography: {
            subtitle1: {fontSize: '18px', fontFamily: 'Unbounded'},
            subtitle2: {fontSize: '14px', fontFamily: 'Unbounded'},
            h5: {fontSize: '22px', fontFamily: 'Unbounded'},
            body1: {fontSize: '12px', fontFamily: 'Unbounded'},
        },
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});

        if (errors[name]) {
            const updatedErrors = {...errors};
            delete updatedErrors[name];
            setErrors(updatedErrors);
        }
    };

    const navigateHome = () => {
        navigate('/')
    }

    const sendRequireCalculate = async () => {
        try {
            const response = await fetch('https://schtern-mail.ru/send-calculating', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setIsOpenModal(true)
            } else {
                const errorData = await response.json();
                console.error('Ошибка при отправке формы:', errorData);
            }
        } catch (error) {
            console.error('Error sending form:', error);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await validationSchema.validate(formData, {abortEarly: false});
            setErrors({});
            await sendRequireCalculate()

        } catch (validationErrors) {
            const formattedErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(formattedErrors);
        }
    };

    const handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{padding: 3, backgroundColor: '#ffffff', maxWidth: 600, margin: 'auto'}}>
                <Typography variant="subtitle1" sx={{fontWeight: 'bold', marginBottom: 2}}>
                    Заявка на предварительный просчет стоимости
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Ширина оконного проема (мм)"
                                name="width"
                                value={formData.width}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                error={!!errors.width}
                                helperText={errors.width}
                                fullWidth
                                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            />
                            <TextField
                                label="Высота оконного проема (мм)"
                                name="height"
                                value={formData.height}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                error={!!errors.height}
                                helperText={errors.height}
                                fullWidth
                                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                select
                                label="Толщина оконного профиля"
                                name="profileThickness"
                                value={formData.profileThickness}
                                onChange={handleChange}
                                error={!!errors.profileThickness}
                                helperText={errors.profileThickness}
                                fullWidth
                            >
                                <MenuItem value="58 мм">58 мм</MenuItem>
                                <MenuItem value="70 мм">70 мм</MenuItem>
                                <MenuItem value="82 мм">82 мм</MenuItem>
                            </TextField>
                            <TextField
                                select
                                label="Тип стеклопакета"
                                name="glassType"
                                value={formData.glassType}
                                onChange={handleChange}
                                error={!!errors.glassType}
                                helperText={errors.glassType}
                                fullWidth
                            >
                                <MenuItem value="Однокамерный">Однокамерный</MenuItem>
                                <MenuItem value="Двухкамерный">Двухкамерный</MenuItem>
                            </TextField>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Количество створок"
                                name="numOfSections"
                                value={formData.numOfSections}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                error={!!errors.numOfSections}
                                helperText={errors.numOfSections}
                                fullWidth
                                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            />
                            <TextField
                                label="Количество открывающихся створок"
                                name="numOfOpeningSections"
                                value={formData.numOfOpeningSections}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                error={!!errors.numOfOpeningSections}
                                helperText={errors.numOfOpeningSections}
                                fullWidth
                                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            />
                        </Stack>
                        <Stack
                            spacing={2}
                            direction={'row'}
                        >
                            <TextField
                                label="Ваше имя"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                error={!!errors.name}
                                helperText={errors.name}
                                fullWidth
                            />
                            <TextField
                                label="Ваш Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                                fullWidth
                            />
                        </Stack>
                        <TextField
                            label="Ваш телефон"
                            name="phone"
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            onKeyPress={handlePhoneKeyPress}
                            error={!!errors.phone}
                            helperText={errors.phone}
                            fullWidth
                            inputProps={{
                                maxLength: 18,
                            }}
                        />


                        <Button
                            type="submit"
                            variant="contained"
                            color="primary" fullWidth
                            sx={{
                                borderRadius: 5,
                                fontSize: { xs: '12px', sm: '14px' },
                                padding: { xs: '6px 12px', sm: '8px 16px' },
                                fontWeight: 'bold',
                                fontFamily: 'Unbounded',
                            }}
                        >
                            Отправить заявку
                        </Button>
                    </Stack>
                </form>
            </Box>
            <ToastSuccess isOpen={isOpenModal} text={'Заявка успешно отправлена!'}  callback={navigateHome}/>
        </ThemeProvider>
    );
};

export default CalculatorForm;
