import React from 'react';
import {Box, Button, Modal, Stack, Typography} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import success from './images/success-send.svg'

const theme = createTheme({
    typography: {
        subtitle1: {fontSize: '18px', fontFamily: 'Unbounded'},
        subtitle2: {fontSize: '14px', fontFamily: 'Unbounded'},
        h5: {fontSize: '22px', fontFamily: 'Unbounded'},
        body1: {fontSize: '12px', fontFamily: 'Unbounded'},
    },
});

interface ToastSuccessProps {
    isOpen: boolean;
    text: string;
    callback?: () => void;
}

const ToastSuccess: React.FC<ToastSuccessProps> = ({isOpen, text, callback}) => {

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={isOpen}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {xs: '90%', sm: '70%', md: '60%', lg: '50%', xl: '40%'},
                        bgcolor: '#FFFFFF',
                        borderRadius: 3,
                        boxShadow: 24,
                        p: 3,
                        textAlign: 'center',
                    }}
                >
                    <Stack
                        sx={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '20%',
                            marginBottom: 2
                        }}>
                        <img src={success} alt='success'/>
                    </Stack>
                    <Typography id="success-modal-title" variant="h5" component="h2" sx={{mb: 2}}>
                        Успешно!
                    </Typography>
                    <Typography id="success-modal-description" variant="subtitle1" sx={{mb: 3}}>
                        {text}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={callback}
                        sx={{
                            borderRadius: 5,
                            fontSize: {xs: '12px', sm: '14px'},
                            padding: {xs: '6px 12px', sm: '8px 16px'},
                            fontWeight: 'bold',
                            fontFamily: 'Unbounded',
                        }}
                    >
                        Закрыть
                    </Button>
                </Box>
            </Modal>
        </ThemeProvider>
    );
};

export default ToastSuccess;
