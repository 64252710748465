import React from 'react';
import {Stack, useMediaQuery, useTheme} from "@mui/material";
import UpperMenu from "../../components/Elements/UpperMenu";
import PageHeader from "../../ui/PageHeader";
import headerMob from "./images/header_mob.svg"
import header from "./images/header.svg";
import Component13 from "../../components/Elements/mainPage/Component13";
import CalculatorForm from "./CalculatorForm";


const Calculator = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <React.Fragment>
            <UpperMenu/>
            <Stack sx={{
                paddingLeft: isMobile ? 0 : 10,
                paddingRight: isMobile ? 0 : 10,
                marginTop: isMobile ? 7 : 12,
                backgroundColor: '#ffffff'
            }}>
                <PageHeader mobileImage={headerMob} image={header}/>
                <CalculatorForm/>
            </Stack>
            <Component13/>
        </React.Fragment>
    );
};

export default Calculator;
