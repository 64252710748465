/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import styles from '../../css/upperMenu.css';
import logo from '../../assest/svgEl/logo.svg';
import burger from '../../assest/svgEl/burger.svg';
import search from '../../assest/svgEl/search.svg';
import ContactForm from '../../components/contact-form/ContactForm.jsx';
import BurgerMenuNew from '../Elements/burger-menu/BurgerMenuNew';
import Stack from "@mui/material/Stack";

const upperMenu = () => {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const [isContactFormVisible, setContactFormVisible] = useState(false);

  const handleToggleContactForm = () => {
    setContactFormVisible(!isContactFormVisible);
  };

  const handleModalClose = () => {
    setContactFormVisible(false);
  };

  const toggleBM = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isDesktop > 480 ? (
        <div>
          <div className="menu-container">
            <div className="menu-container__item">
              <Link to="/">
                <img alt="logo" src={logo} className="menu__logo"></img>
              </Link>
            </div>
            <Stack sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
              width: '100%',
              marginRight: 4,
            }}
            gap={4}>


            <div className="menu-container__item">
              <Link to="/calculator"> Калькулятор окон</Link>
            </div>

            <div className="menu-container__item">
              <Link to="/kontakty">Контакты</Link>
            </div>
            {/*<div className="menu-container__item">*/}
            {/*  <Link to="/not-found">Где купить</Link>*/}
            {/*</div>*/}
            <div className="menu-container__item" onClick={handleToggleContactForm}>
              Бесплатный замер
            </div>
            <div className="menu-container__item" style={{color: '#222222'}}>
              <a href="tel:+78005505878" style={{ color: '#222222' }}>
                8 800 550-58-78
              </a>
            </div>
            </Stack>

            <div className="menu-container__item" onClick={toggleBM}>
              <img alt="burger" src={burger} className="menu-container__item--burger"></img>
            </div>
          </div>
          {isContactFormVisible && (
            <div className="modal-overlay">
              <ContactForm onClose={handleModalClose} />
            </div>
          )}
        </div>
      ) : (
        <div className="mob-menu__container">
          <Link to="/">
            <img alt="logo" src={logo} className="mob-menu__logo"></img>
          </Link>
          <div className="mob-menu__btn" onClick={toggleBM}>
            <img alt="burger" src={burger}></img>
          </div>
        </div>
      )}

      <BurgerMenuNew isOpen={isBurgerMenuOpen} toggleBM={toggleBM} />
    </div>
  );
};

export default upperMenu;
